/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: block;
        }
    }

    &-RatingWrapper {
        margin-block-end: 30px;

        .FieldGroup-Wrapper {
            padding-block: 10px;
            border-block-end: 1px solid rgba(0,0,0,.5);

            &,
            &:not(:last-child) {
                margin-block-end: 0;
            }

            &_RatingGlobal {
                .ProductReviewForm-Legend {
                    font-weight: 300;
                    font-size: 15px;

                    &::after {
                        display: none;
                    }
                }

                .ProductReviewForm-Value {
                    min-width: 25px;
                    margin-inline-start: 15px;
                    font-weight: 700;
                    font-size: 14px;
                }

                .ReviewStar {
                    width: 24px;
                    min-width: 24px;
                    font-size: 26px;
                    cursor: default;

                    &:hover:not([checked]):not(:checked),
                    &:hover ~ input:not([checked]):not(:checked) {
                        --star-outline-color: var(--secondary-dark-color);

                        color: var(--color-white);
                    }
                }
            }
        }
    }

    &-Rating {
        justify-content: flex-end;
        align-items: center;

        @include mobile {
            max-width: none;
        }

        legend {
            width: auto;
            float: left;
            margin-inline-end: auto;
            padding-block-end: 0;
            line-height: 1.7;
            order: 6;
        }

        .ReviewStar {
            width: 20px;
            min-width: 20px;
            min-height: 0;
            font-size: 20px;
            margin-block-start: 0;
        }
    }

    & &-Button {
        width: 100%;
    }

    &-Value {
        min-width: 20px;
        margin-inline-start: 20px;
        font-size: 12px;
        font-weight: 500;
        color: var(--primary-base-color);
        text-align: end;
    }

    .ProductReviewRating-Counter {
        display: none;
    }
}
