/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartGiftProducts {
    flex: 1 1 auto;
    display: flex;
    // flex-direction: column;
    //gap: 15px;

    &:not(.swiper-wrapper) {
        > *:nth-child(n+6) {
            display: none;
        }
    }

    &.swiper-wrapper {
        display: flex;
        align-items: stretch;
        grid-column-gap: 0;
    }

    &-Summary {
        position: sticky;
        inset-block-end: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-block-start: 15px;
        margin-inline: calc(-1 * var(--popup-content-padding));
        padding: 15px;
        background-color: var(--color-white);
        border-block-start: 1px solid var(--primary-divider-color);
    }

    &-SummaryProducts {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-SummaryProduct {
        width: 67px;
        aspect-ratio: 1;
        border-radius: 50%;

        &:not(:first-child) {
            margin-inline-start: 20px;

            &::before {
                content: "+";
                font-size: 16px;
                position: absolute;
                top: 50%;
                left: -10px;
                transform: translate(-50%, -50%);
            }
        }

        &_isPlaceholder {
            border: 1px solid var(--secondary-base-color);
        }

        .Image {
            display: block;
        }
    }

    &-Submit {
        /* --button-height: 35px;
        --button-hover-height: 35px;
        --button-padding: 25px;
        --button-hover-padding: 25px;
        --button-font-size: 11px; */
        --button-background: var(--secondary-base-color);
        --button-border: var(--secondary-base-color);
        --button-hover-background: var(--secondary-dark-color);
        --button-hover-border: var(--secondary-dark-color);

        width: auto;
    }
}
