/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --variation-item-width: 34px;
    --variation-item-margin: 6px;
}

@mixin horizontalScrollFilters() {
    margin-inline: calc(var(--popup-content-padding) * -1);
    padding-inline: var(--popup-content-padding) 0;

    > * {
        flex-wrap: nowrap;
        padding-inline: 0 var(--popup-content-padding);
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-block-end: 15px;
    }
}

.ProductVariations {
    margin-bottom: 30px;

    &-ContainerList {
        min-height: 1px;
    }

    &-WrapperList {
        display: flex;

        &_full,
        &_select {
            flex-direction: column;
        }
    }

    &-List {
        > * {
            margin-bottom: 15px;
        }

        &_isLimited {
            display: flex;
            margin-inline: calc(var(--variation-item-margin) * -1);

            > * {
                margin-inline: var(--variation-item-margin);
                margin-bottom: 0;
            }
        }
    }

    &-Select {
        margin-block-start: 0;

        &Title {
            margin-block-end: 0.5em;
            font-size: 14px;
            font-weight: 600;
        }
    }

    &-ShowMore {
        @include no-txt();

        display: none;
        flex: 0 0 auto;
        position: relative;
        width: var(--variation-item-width);
        height: var(--variation-item-width);
        border-radius: 50%;
        border: 1px solid var(--color-black);
        margin-left: calc(var(--variation-item-margin) * 2);
        cursor: pointer;

        &_isVisible {
            display: block;
        }

        &::before {
            @include full-cover();

            content: "+";
            font-size: 24px;
            font-weight: 600;
            line-height: calc(var(--variation-item-width) - 2px);
            text-align: center;
            text-indent: 0;
        }
    }

    &-Filters {
        display: flex;
        // flex-wrap: wrap;
        margin-bottom: 15px;

        &Inner {
            display: flex;
            flex-wrap: wrap;
            column-gap: 15px;
            row-gap: 5px
        }

        .Popup & {
            @include horizontalScrollFilters();
        }

        @include mobile() {
            @include horizontalScrollFilters();
        }
    }

    &-Filter {
        font-size: 12px;
        opacity: .8;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &_isActive {
            opacity: 1;
            text-decoration: underline;
        }
    }
}
