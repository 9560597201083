/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ProductUsage {
    &-Content {
        @include desktop {
            padding-block: 30px;
        }
    }

    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-Disclaimer {
        display: flex;
        align-items: center;
        padding: 15px;
        background-color: var(--secondary-light-color);
        border-block-start: 1px solid var(--primary-divider-color);

        &:last-child {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        &::before {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 29px;
            height: 29px;
            background: url($path-img + 'info.svg') no-repeat center;
            background-size: contain;
            margin-right: 15px;
        }

        p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 13px;
        }
    }

    &-Precaution {
        .ExpandableContent {
            border-bottom-width: 0;

            &-Heading {
                text-transform: none;
            }

            @include desktop {
                border-top: 1px solid var(--expandable-content-divider-color);

                &-Button {
                    display: flex;
                    padding-block: 14px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;

                    &_isContentExpanded {
                        @include expanded-content;
                    }
                }
            }

            /*&-Content {
                @include desktop {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                }

                &_isContentExpanded {
                    @include desktop {
                        @include expanded-content;
                    }
                }
            }*/
        }

        .ProductAttributeValue {
            font-size: var(--paragraph-font-size);
        }
    }
}
