/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductGallery {
    height: auto;
    margin-inline: calc(-1 * var(--content-wrapper-padding-mobile));
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: flex-start;

    .Image {
        display: block;
        height: auto;
        padding-block-start: var(--ratio-product-image);
        padding-block-end: 0;
    }

    .swiper-pagination {
        bottom: 25px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }


    @include mobile {
        &-SliderWrapper {
            flex: 0 0 100%;
            height: 100%;
            max-width: 100%;
        }

        .VideoThumbnail {
            display: block;
            width: 100%;
            text-align: center;

            .VideoThumbnail-Button {
                // height: 100vh;
            }

            .VideoThumbnail-Thumbnail {
                display: flex;
                max-height: 100vh;

                .Image-Image {
                    max-height: 100%;
                    max-width: 100%;
                    width: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @include desktop {
        &-SliderWrapper {
            flex: 0 0 calc(100% - 100px);
            height: 100%;
            max-width: calc(100% - 100px);
        }

        & + .ProductPage-TagsWrapper {
            padding-left: 0px !important;
            width: calc(100% - 100px);
            margin-left: 100px;
        }
    }

    &-SliderImageButton {
        display: block;
        width: 100%;

        @include desktop {
            cursor: zoom-in;

            &_isZoomEnabled {
                cursor: zoom-out;
            }
        }
    }

    &-SliderImage {
        display: block;
    }

    &-Grid {
        height: 100%;
        overflow: hidden;
    }

    .CarouselScroll {
        margin-top: 0;
    }

    .CarouselScroll-ContentWrapper {
        width: 100px;
        align-items: flex-start;
    }

    .CarouselScroll-Content {
        flex-direction: column;

        .CarouselScrollItem {
            margin: 0 0 1em 0 !important;
            border: none;
            width: 70px;

            &_isActive {
                border: 1px solid #000;
            }

            .ProductGalleryThumbnailImage {
                padding: 0 0;
                height: 70px;

                .Image {
                    height: 100%;
                }
            }
        }
    }
}

.ImageZoomPopup_isVisible {
    .ProductGallery {
        display: block;

        .ProductGallery-SliderWrapper {
            .Slider-Wrapper {
                display: flex;
            }

            .Slider-Arrow {
                display: block;

                &_isPrev {
                    left: 50px !important;
                }

                &_isNext {
                    right: 50px !important;
                }
            }

            display: block;
            max-width: 100%;
            width: 100%;

            .Image, .VideoThumbnail {
                display: block;
                width: 100%;
                text-align: center;

                .VideoThumbnail-Thumbnail {
                    display: flex;
                    max-height: 100vh;
                }
            }

            .Image-Image {
                max-height: 100%;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .ProductGallery-Additional_isImageZoomPopupActive {
            display: none;
        }
    }
}

.ProductPopup {
    @include desktop {
        .ProductPage-WrapperGallery {
            .ProductTags {
                margin-left: 3em;
            }

            .ProductGallery {
                .ProductGallery-SliderWrapper {
                    flex: 0 0 100%;
                    height: 100%;
                    max-width: 100%;
                    padding: 0 5em;
                }
            }
        }
    }
}
