/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTag {
    padding: 1px 5px;
    background-color: var(--secondary-base-color);
    border: 1px solid transparent;
    font-size: 9px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-white);

    @include desktop {
        font-size: 10px;
    }

    &_type {
        &_produit_pro {
            background-color: var(--pro-dark-color);
        }

        &_produit_reserve_pro {
            background-color: var(--color-white);
            border-color: var(--pro-dark-color);
            color: var(--pro-dark-color);

            .ProductActions & {
                border-width: 2px;
                font-weight: 600 !important;
            }
        }

        &_produit_etudiant {
            background-color: var(--student-base-color);
        }
    }
}
