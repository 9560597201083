/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include desktop {
        min-height: 0;
    }

    del {
        opacity: .6;
    }

    &-Price {
        &::after {
            content: attr(data-label);
            font-size: 8px;
            font-weight: 500;
            line-height: 1.2;
            //position: relative;
            //top: -1.2em;
            align-self: flex-start;
            margin-left: 0.4em;
        }

        &.price-excluding-tax {
            font-weight: 400;
        }

        &_isPublic {
            font-weight: 400;
            font-size: .65em;
            color: #6f6f6f;

            .ProductPrice-PriceValue {
                font-size: 1em !important;
            }

            &::after {
                font-size: .6em;
            }
        }
    }

    &-PriceValue {
        margin-top: 0;
        line-height: 1.3;

        @include mobile {
            font-size: 14px;
        }

        @include desktop {
            line-height: 1.3;
            font-size: 15px;
        }
    }

    &-BundleFrom {
        display: flex;
        flex-direction: column;
    }

    &-BundleTo {
        display: none;
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 0;
                    margin-block-end: 0;
                }
            }
        }
    }

    &-UserGroupLabel {
        margin-block-end: 0.2em;
        padding: .15em .5em;
        background-color: var(--color-black);
        font-weight: 500;
        font-size: 8px;
        color: var(--color-white);
        text-transform: uppercase;
        line-height: normal;
    }

    &_isPro,
    &_isStudent {
        .ProductPrice {
            &-Price {
                &_isOldPrice {
                    display: inline-flex;
                    align-items: baseline;
                    margin-block-start: 0.15em;
                    margin-inline: 0;
                    font-size: 1em;
                    line-height: normal;

                    .ProductPrice-PriceValue {
                        font-size: .85em;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    &_isPro {
        .ProductPrice {
            &-Price {
                &.price-excluding-tax {
                    font-weight: inherit;
                }

                &_isOldPrice {
                    &.price-excluding-tax {
                        font-weight: 500;
                    }
                }
            }

            &-UserGroupLabel {
                background-color: var(--pro-dark-color);
            }
        }
    }

    &_isStudent {
        .ProductPrice {
            &-UserGroupLabel {
                background-color: var(--student-dark-color);
            }
        }
    }
}
