/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DocumentProduct {
    display: grid;
    grid-template-columns: 65px auto;
    gap: 15px;
    align-items: center;

    &-VisualThumbnail {
        display: block;
        height: 0;
        padding-block-end: var(--ratio-product-image);

        .Image-Image {
            width: 100%;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
    }

    &-Title {
        margin-block-end: 8px;
        font-weight: 700;
    }

    &-Link {
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: .15em;

        @include mobile {
            width: auto;
        }
    }
}
