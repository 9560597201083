/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductConfigurableAttributes {
    @include desktop {
        padding-block: 0;
    }

    &-Expandable:first-of-type {
        border-block-start: 1px solid var(--expandable-content-divider-color);
    }

    &-ExpandableContentHeading {
        text-transform: uppercase;
    }

    &-DropDownList {
        margin-top: 3px;
    }
}
