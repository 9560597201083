/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductRestricted {
    padding: 20px 15px 30px;
    background-color: var(--secondary-light-color);
    text-align: center;

    @include mobile() {
        margin-left: calc(-1 * var(--content-wrapper-padding-mobile));
        margin-right: calc(-1 * var(--content-wrapper-padding-mobile));
    }

    &-Title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;

        &::before {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 29px;
            height: 29px;
            background: url(/style/images/info.svg) no-repeat center;
            background-size: contain;
            margin-right: 10px;
        }
    }

    &-Content {
        display: flex;
    }

    &-Group {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;

        &:first-child {
            &:not(:last-child) {
                padding-right: 15px;
                border-right: 1px solid var(--primary-divider-color);
            }

            &:last-child {
                flex-basis: 100%;
            }
        }

        &:last-child {
            &:not(:first-child) {
                padding-left: 15px;
            }
        }

        &Title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            margin-bottom: 1em;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;

            .UserIcon {
                width: 35px;
                height: 35px;
                pointer-events: none;

                .ProductPage & { // hidden in product page
                    display: none;
                }
            }
        }

        &Content {
            margin-bottom: 20px;

            p {
                margin-block-end: 0;
                font-size: 12px;
                font-weight: 300;
            }
        }

        &Footer {
            margin-top: auto;
        }

        &Link {
            font-size: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }
}
