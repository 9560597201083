/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductBundleOptions {
    &Popup {
        .Popup-Heading {
            margin-block-end: .8em;
        }

        .Popup-Content {
            height: 100%;
        }
    }
}

.ProductBundleItem {
    &-Wrapper {
        display: none;
        margin-block-start: 0;

        &_isVisible {
            display: block;
        }

        .FieldGroup {
            &-Wrapper {
                margin-block-start: 35px;

                .FieldGroup {
                    &_isValid {
                        border-inline-start: none;
                        padding-inline-start: 0;
                    }
                }

                .Field-Wrapper {
                    &_type {
                        &_radio {
                            display: none;
                        }
                    }
                }

                &_type {
                    &_obligatoire, &_gift {
                        .Field-Wrapper {
                            display: none;
                        }
                    }
                }
            }
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_numberWithControls {
                        margin-top: 10px;
                        margin-left: 100px;
                    }
                }
            }
        }
    }

    &-Product {
        display: flex;
        flex: 1 1 auto;

        &Image {
            flex: 0 0 auto;
            width: 85px;
            margin-right: 15px;

            .Image {
                display: block;
                height: auto;
                padding-top: var(--ratio-product-image);
            }

            .Image-Image {
                width: calc(100% - var(--product-card-image-padding) * 2);
                height: calc(100% - var(--product-card-image-padding) * 2);
                inset-block-start: var(--product-card-image-padding);
                inset-inline-start: var(--product-card-image-padding);
            }
        }

        &Content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }

        &Title {
            margin-bottom: 0.35em;
            font-weight: 700;
            font-size: 15px;
        }

        .ProductReviewRating {
            margin-bottom: 8px;
        }

        &Sku {
            margin-bottom: 2px;
            font-weight: 500;
            font-size: 12px;
        }

        &Quantity {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 10px;
        }

        &PriceWrapper {
            display: flex;
            align-items: flex-end;
            gap: 10px;
        }

        &Price {
            margin-block-end: 5px;
        }

        &Qty {
            margin-block-end: 5px;
            font-size: 15px;
            font-weight: 700;
        }

        &Free {
            span {
                display: inline-flex;
                padding: .25em 1em;
                background-color: var(--primary-base-color);
                color: var(--color-white);
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        &Actions {
            --button-height: 35px;
            --button-hover-height: var(--button-height);
            --button-font-size: 11px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block-start: auto;

            .Button {
                @include mobile {
                    width: auto;
                }
            }
        }

        &Selected {
            --button-background: transparent;
            --button-border: transparent;
            --button-padding: 0;
            --button-color: var(--primary-base-color);
            --button-hover-background: var(--button-background);
            --button-hover-border: var(--button-border);
            --button-hover-padding: var(--button-padding);
            --button-hover-color: var(--button-color);

            @include button;

            cursor: auto;

            &::before {
                content: "";
                display: block;
                width: 13px;
                height: 10px;
                margin-inline-end: 8px;
                background: url($path-img + 'check-pink.svg') no-repeat center;
                background-size: contain;
            }

            @include mobile {
                width: auto;
            }
        }

        &Link {
            font-size: 12px;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: .15em;
            cursor: pointer;

            &:first-child {
                margin-left: auto;
                margin-bottom: 10px;
            }
        }
    }

    &-Radio, &-Checkbox {
        &:not(:last-child) {
            margin-block-end: 15px;
        }

        &_customQuantity {
            display: block;

            .Field-Wrapper {
                &_type {
                    &_number {
                        flex: 0 0 auto;
                        margin-inline-start: 15px;
                    }
                }
            }

            @include narrow-mobile {
                flex-wrap: wrap;

                .Field-Wrapper {
                    &_type {
                        &_number {
                            flex-basis: 100%;
                            margin-inline-start: 0;
                            margin-top: 10px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &-Radio {
        &_isHidden {
            display: none;
        }

        &_isChecked {
            // ...
        }
    }

    &-Unavailable {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: -30px;

        &Title {
            display: flex;
            align-items: center;
            margin-inline-end: 1.5em;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 0;

            &::before {
                flex: 0 0 auto;
                content: "";
                display: block;
                width: 17px;
                height: 17px;
                margin-right: 6px;
                background: url($path-img + 'info-red.svg') no-repeat center;
                background-size: contain;
            }
        }

        &Link {
            font-weight: 500;
            font-size: 12px;
            text-decoration: underline;
            text-underline-offset: 0.15em;
            cursor: pointer;
            width: auto;
        }
    }

    &-Section {
        &_type {
            &_unavailable {
                padding-block: 2em 1em;
                margin-top: 17px;

                &::before {
                    @include full-cover(true);

                    right: -40px;
                    left: -40px;
                    background-color: var(--secondary-light-color);
                    width: auto;
                }

                .ProductBundleItem-Product {
                    &Stock {
                        display: none;
                    }
                }
            }
        }

        &Header {
            margin-bottom: 25px;
        }

        &Title {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 700;
            text-align: center;
        }

        &Message {
            font-size: 12px;
            text-align: center;
            font-weight: 300;
            max-width: 330px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ProductPrice {
        font-size: 15px;
    }

    &-Category {
        margin-bottom: 0 !important;

        &:first-child {
            border-block-start: 1px solid var(--expandable-content-divider-color);
        }

        @include desktop {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }
    }

    &-ExpandableContent {
        &Heading {
            font-weight: 700 !important;
            text-transform: uppercase;
        }

        &SubHeading {
            font-size: 12px;
        }

        &Content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            margin-block-start: 0;

            &_isContentExpanded {
                opacity: 1;
                max-height: 100%;
                margin-block-start: 1rem;
                margin-block-end: 3rem;
            }
        }

        &Button {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @include desktop {
                padding-block: 14px;
            }

            .AddIcon,
            .MinusIcon {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
        }
    }

    &-Filters {
        display: flex;
        // flex-wrap: wrap;
        margin-inline: calc(var(--popup-content-padding) * -1);
        padding-inline: var(--popup-content-padding) 0;

        &Inner {
            display: flex;
            column-gap: 15px;
            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            // -ms-overflow-style: -ms-autohiding-scrollbar;
            padding-block-end: 15px;
            padding-inline: 0 var(--popup-content-padding);
        }
    }

    &-Filter {
        font-size: 12px;
        opacity: .8;
        cursor: pointer;

        &:last-child {
            margin-inline-end: 0;
        }

        &_isActive {
            opacity: 1;
            text-decoration: underline;
        }

        &_isAll {
            font-weight: 600;
        }
    }

    &-FilterGroup {
        display: flex;
        column-gap: 15px;

        &_hasToggle {
            .ProductBundleItem-FilterGroup {
                &Toggle {
                    display: inline-flex;
                }

                &Options {
                    display: none;
                }
            }
        }

        &_isExpanded {
            .ProductBundleItem-FilterGroupOptions {
                display: flex;
            }
        }

        &Toggle {
            display: none; // only displayed if parent has "hasToggle" mod
            align-items: center;
            gap: 3px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;

            .ChevronIcon {
                inline-size: 13px;
                block-size: 13px;
            }
        }

        &Options {
            display: flex;
            column-gap: 15px;
        }
    }
}

