/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.PushProductHomepage {
    &-Title {
        @include homepageSectionTitle();

        @include desktop {
            margin-block-end: 2em;
        }
    }

    &-Content {
        // ...
    }

    .ProductListWidget {
        @include mobile {
            padding-block-end: 50px;
        }

        @include desktop {
            margin-inline: 65px;
        }

        @media (min-width: 1730px) {
            margin-inline: 0;
        }
    }

    .ProductCard-Link {
        padding-bottom: 2px;
    }

    .swiper-pagination {
        @include desktop {
            display: none;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        @include mobile {
            display: none;
        }

        @include desktop {
            width: 64px;
            height: 64px;
            margin-top: 0;
            transform: translateY(-50%);

            &::after {
                font-size: 18px;
            }
        }
    }

    .swiper-button-next {
        inset-inline-end: -15px;

        @media (min-width: 1730px) {
            margin-inline-end: -90px;
        }
    }

    .swiper-button-prev {
        inset-inline-start: -15px;

        @media (min-width: 1730px) {
            margin-inline-start: -90px;
        }
    }
}
