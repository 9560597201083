/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductListContentPush {
    height: 100%;
    margin-block-end: 0;

    &::before {
        display: none;
    }

    &-Visual {
        .Image-Image {
            display: block;
            object-fit: cover;
        }
    }

    &-SuperTitle {
        font-weight: 700;
    }

    &-Title {
        font-weight: 300;
    }

    &-Link {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .ProductListContentPush {
            &-Visual {
                flex: 1 1 auto;
            }

            &-Image {
                position: absolute;
                inset-block-start: 50%;
                inset-inline-start: 0;
                width: 100%;
                transform: translate(0, -50%);
            }

            &-Content {
                flex: 0 0 auto;
                margin-block-start: -12%;
                padding-block: 22% 7%;
                padding-inline: 10%;
                background-color: var(--primary-base-color);
                clip-path: polygon(0 18%, 100% 0, 100% 100%, 0% 100%);

                * {
                    color: #fff;
                }
            }

            &-SuperTitle {
                margin-block-end: 1em;
                font-size: clamp(11px, calc(0.42vw + 9.42px), 16px);
            }

            &-Title {
                font-size: clamp(14px, calc(1.35vw + 8.94px), 30px);
                line-height: 1.2;

                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 12px;
                    margin-block-start: 6px;
                    background: url('/style/images/arrow-link.svg') no-repeat center;
                    background-size: 100% auto;
                    transition: opacity .3s ease-out;

                    @include desktop {
                        width: 41px;
                        opacity: 0;
                    }
                }
            }
        }

        @include desktop {
            &:hover {
                .ProductListContentPush-Title {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-Inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 7%;
        background-color: var(--secondary-light-color);

        .Image {
            padding-block-end: 0;
        }

        .Image-Image {
            position: relative;
            top: auto;
            width: auto;
            max-width: 100%;
            height: auto;
            margin-inline: auto;
        }

        .ProductListContentPush {
            &-Visual {
                margin-bottom: 15px;
            }

            &-Content {
                text-align: center;
            }

            &-Title {
                font-size: clamp(16px, calc(1.19vw + 11.53px), 30px);
            }
        }
    }
}
