/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviews {
    &-ButtonMobile {
        .ProductReviewRating {
            margin-left: 15px;
        }

        .ChevronIcon {
            margin-left: auto;
        }
    }

    &-Summary {
        flex-wrap: nowrap;
        justify-content: space-between;
        column-gap: 20px;

        > * {
            max-width: 50%;
        }

        &Title {
            margin-bottom: 5px;
            font-weight: 700;
            font-size: 15px;
        }

        &RatingWrapper {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

        & &Rating {
            margin-top: 0;
        }

        &Details {
            margin-inline-start: 0;
            font-size: 14px;
            color: var(--primary-base-color);
            white-space: nowrap;
        }

        &Counter {
            margin-top: 5px;
            margin-left: 3px;
            font-size: 12px;
        }
    }

    & &-Button {
        --hollow-button-border: $color-black;
        --hollow-button-color: $color-black;

        /* @include mobile {
            width: auto;
            margin-right: 0;
            margin-top: 0;
        } */
    }

    &_isMobile {
        .ProductReviews {
            &-Button {
                width: auto;
                margin-right: 0;
                margin-top: 0;
            }
        }
    }

    // @include desktop {

    &_isDesktop {
        padding-block: 60px;

        .ProductTabs-Wrapper & {
            max-width: 100%;
        }

        .ProductReviews {
            &-Wrapper {
                padding-inline: 0;
            }

            &-Content {
                display: grid;
                grid-template-columns: 25% 75%;
            }

            &-Title {
                margin-block: 0 1.4em;
                font-weight: 300;
                font-size: 40px;
                text-align: center;
                line-height: 1.2;
                text-transform: none;
            }

            &-NoRating {
                margin-block-end: 20px;
            }

            &-Summary {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-inline-end: 30px;

                > * {
                    width: 100%;
                    max-width: 240px;
                }

                &:last-child { // no comment yet
                    grid-column-start: span 2;
                    align-items: center;
                    padding-inline-end: 0;

                    > * {
                        width: auto;
                        max-width: none;
                    }
                }

                &Content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 35px;
                    padding-right: 0;
                }

                &Title {
                    margin-bottom: 25px;
                    text-transform: uppercase;
                }

                &RatingWrapper {
                    flex-flow: column-reverse;
                }

                &Details {
                    margin-left: 0;
                    margin-bottom: 10px;
                    font-size: 30px;
                    text-align: center;
                }

                &Counter {
                    margin-top: 15px;
                    margin-left: 0;
                }
            }
        }

        .ProductReviews-Button {
            position: relative;
            top: auto;
            bottom: auto;
            margin: 0;
        }
    }
}

.ProductReviewsYotpo {
    .yotpo-head {
        display: none !important;
    }

    .yotpo-filters-top-panel {
        input {
            position: initial;
        }
    }
}
