/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPage {
    @include mobile {
        margin-inline: var(--content-wrapper-padding-mobile);
        padding-block-end: 30px;
    }

    &-TagsWrapper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding: 20px;

        .ProductWishlistButton {
            &:first-child {
                margin-left: auto;
            }
        }
    }

    &-PerfectCorpWrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1001;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);

        &.PerfectCorp-Opened {
            width: auto;
            height: auto;

            &.PerfectCorp-Loaded {
                .PerfectCorpPicker {
                    display: block;

                    &-AddToCartWrapper {
                        display: block;
                    }
                }
            }
        }

        &.PerfectCorp-Closed {
            #YMK-module {
                display: none !important;
            }
        }

        .PerfectCorpHidden {
            display: none !important;
        }

        .PerfectCorpPicker {
            position: relative;
            width: 100%;
            height: 80px;
            display: none;

            &-AddToCartWrapper {
                position: relative;
                width: 100%;
                height: 50px;
                background: var(--color-white);
                display: none;

                .Button {
                    width: 100%;
                    border-radius: 0;
                    color: var(--color-black);
                }
            }

            &-PerfectCorpPickerList {
                background: var(--color-white);
                padding: 14px;
                overflow: auto;
                display: flex;
                gap: 15px;
                position: absolute;
                width: 100%;
                height: 100%;
            }

            &-PerfectCorpPickerItem {
                width: 37px;
                height: 37px;
                flex-shrink: 0;
                border-radius: 50%;
                cursor: pointer;
                position: relative;

                &.Selected::after {
                    content: '';
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    bottom: -5px;
                    left: -5px;
                    border: 2px solid var(--primary-dark-color);
                    border-radius: 50%;
                }

                img {
                    width: 37px;
                    height: 37px;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }

    &-Wrapper {
        @include desktop {
            grid-template-columns: 55% 38%;
            grid-column-gap: 7%;
            align-items: flex-start;
        }
    }

    &-ProductActions {
        @include desktop {
            grid-area: actions;
            position: sticky;
            top: 0;
        }
    }

    &-RelatedTutorials {
        @include mobile {
            padding-block: 30px;
            margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
        }

        @include desktop {
            padding-block: 60px;
        }
    }

    .AmastyBanners_2 {
        display: none; // @TODO
    }

    .ProductList-ProductCrossSell {
        padding: 2em 0;

        @include mobile {
            padding-left: var(--content-wrapper-padding-mobile);
            padding-right: var(--content-wrapper-padding-mobile);
            -webkit-margin-after: 40px;
            margin-bottom: 40px;
        }

        &Title {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            padding: 2em 0;
        }

        .ProductListPage {
            @include mobile {
                grid-template-columns: 1fr;
                gap: 15px;

                @include product-card-horizontal();
            }

            @include desktop {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

.ProductPopup {
    .ProductList-ProductCrossSell {
        display: none;
    }
}
