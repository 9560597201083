/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CategoryProductList {
    @include desktop {
        inset-block-start: 0;
    }

    // From .ProductListPage
    display: grid;
    grid-template-columns: repeat(var(--product-list-page-columns), 1fr);
    grid-auto-rows: max-content;
    grid-column-gap: var(--product-list-page-column-gap);
    grid-row-gap: var(--product-list-page-row-gap);

    > .ProductListPage {
        display: contents; // remove from flux
    }

    > .ProductList-LoadButtonWrapper,
    > .Pagination {
        grid-column-start: span var(--product-list-page-columns); // full width inside grid
    }

    &-Page {
        &_layout {
            &_grid {
                grid-column-gap: 20px;
                grid-row-gap: 30px;

                @include desktop {
                    grid-row-gap: 50px;
                }
            }
        }
    }
}
