/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartGiftProduct {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-inline-end: 15px;

    &.swiper-slide {
        height: auto;
    }

    &-Visual {
        flex: 0 0 auto;

        &Thumbnail {
            display: block;
            width: var(--cart-item-product-visual-width);
            height: auto;
            aspect-ratio: var(--aspect-ratio-product-image);
            margin-inline: auto;
        }
    }

    &-Content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    &-Title {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
    }

    &-ProductLink {
        margin: 10px 0;
        width: 100%;
        text-align: center;
    }

    &-Actions {
        --button-height: 35px;
        --button-hover-height: 35px;
        --button-padding: 25px;
        --button-hover-padding: 25px;
        --button-font-size: 11px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 0;
        margin-block-start: auto;

        .Field {
            margin-block-start: 0;
        }

        &_hasQty {
            .CartGiftProduct-SelectButton {
                display: none;
            }
        }

        &_noQty {
            .CartGiftProduct-Chosen,
            .CartGiftProduct-QuantityWrapper {
                display: none;
            }
        }
    }

    &-SelectButton {
        width: auto;

        &[disabled] {
            opacity: .5;
        }
    }

    &-Chosen {
        --button-background: transparent;
        --button-border: transparent;
        --button-padding: 0;
        --button-color: var(--primary-base-color);
        --button-hover-background: var(--button-background);
        --button-hover-border: var(--button-border);
        --button-hover-padding: var(--button-padding);
        --button-hover-color: var(--button-color);

        @include button;

        cursor: auto;

        &::before {
            content: "";
            display: block;
            width: 13px;
            height: 10px;
            margin-inline-end: 8px;
            background: url($path-img + 'check-pink.svg') no-repeat center;
            background-size: contain;
        }

        @include mobile {
            width: auto;
        }
    }
}
