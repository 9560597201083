/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductBundleButton {
    display: flex;
    align-items: center;
    width: 100%;
    padding-block: 10px;
    padding-inline: var(--content-wrapper-padding-mobile);
    border: {
        width: 1px 0;
        style: solid;
        color: var(--primary-divider-color);
    }

    text-align: left;
    cursor: pointer;

    @include mobile {
        margin-left: -14px;
        margin-right: -14px;
        width: calc(100% + 28px);
    }

    & + .ProductBundleButton {
        border-top-width: 0;
    }

    &-Inner {
        flex: 1 1 auto;
    }

    .ChevronIcon {
        flex: 0 0 auto;
    }

    &-Subtitle {
        margin-block-end: .2em;
        font-size: 11px;
        font-weight: 400;
    }

    &-Title {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &-TitleProduct {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    &-TitlePrice {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        margin-top: 3px;

        .ProductPrice {
            font-size: 15px;
        }

        > span {
            text-transform: none;
        }
    }

    &-Picture {
        flex: 0 0 auto;
        width: 24px;
        aspect-ratio: 1;
        border-radius: 50%;

        .Image-Image {
            object-fit: cover;
        }

        & + span {
            margin-block: 5px;
            font-size: 12px;
        }
    }

    &-Unavailable {
        margin-block-end: 0.3em;
        font-weight: 500;
    }
}
