/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-list-page-column-gap: 20px;
    --product-list-page-row-gap: 55px;
    --product-list-page-columns: 4;

    @include tablet {
        --product-list-page-columns: 3;
    }

    @include mobile {
        --product-list-page-row-gap: 25px;
        --product-list-page-columns: 2;
    }
}

.ProductListPage {
    grid-template-columns: repeat(var(--product-list-page-columns), 1fr);
    grid-column-gap: var(--product-list-page-column-gap);
    grid-row-gap: var(--product-list-page-row-gap);

    @include mobile {
        padding-inline: 0;
    }

    & + & {
        .ProductListContentPush {
            display: none;
        }
    }

    &.swiper-wrapper {
        display: flex;
        align-items: stretch;
        grid-column-gap: 0;

        .ProductCard {
            height: auto;
        }
    }

    &-ShowMore {
        display: none;

        &Link {
            gap: 0.3em;
            font-weight: 500;
            font-size: 14px;
            color: var(--link-color);

            &::before {
                content: "+";
                font-family: Arial, sans-serif;
                font-size: 18px;
                line-height: 14px;
                text-decoration: none;
            }

            span {
                font-weight: 500;
                font-size: 14px;
                color: var(--link-color);
                text-decoration: underline;
            }

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &.Button_isWidgetExpanded {
                &::before {
                    content: "\002212"; // minus sign
                }
            }
        }
    }

    div[data-content-type^='Tutorial_Step'] & {
        @include mobile {
            &_isWidget {
                .ProductCard:nth-of-type(n+4) {
                    display: none;
                }

                &Expanded {
                    .ProductCard:nth-of-type(n+4) {
                        display: inherit;
                    }
                }
            }

            &-ShowMore {
                display: block;
            }
        }
    }
}
