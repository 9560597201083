/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';


.ProductListWidget {
    &-Page {
        grid-column-gap: 20px;
        grid-row-gap: 50px;

        /*@include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }*/

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mobile {
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &.swiper {
        @include mobile {
            padding-block-end: 24px;
        }

        &:not(.swiper-scrollbar-disabled) {
            margin-bottom: 75px;

            @include mobile {
                margin-bottom: 50px;
            }
        }

        .swiper-scrollbar {
            background-color: var(--primary-light-color);

            &.swiper-scrollbar-horizontal {
                width: 100%;
                height: 4px;
                left: 0;
            }
        }

        .swiper-scrollbar-drag {
            background-color: var(--primary-dark-color);
        }
    }
}
