/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAlerts {
    &-InStock,
    &-PriceDrop {
        padding: 20px 15px;
        text-align: center;
        color: var(--default-color);
        cursor: default;
        font-size: 14px;

        &::before {
            @include full-cover(true);

            background-color: var(--primary-base-color);
            opacity: .45;
        }

        &:hover {
            color: var(--default-color);
        }

        [dir="rtl"] &,
        [dir="ltr"] & {
            text-align: center;
        }

        &Title {
            margin-bottom: 15px;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
        }

        &Message {
            p {
                margin: 0;
                font-weight: 300;
                font-size: 13px;
            }
        }

        &Link {
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-SubscribeForm {
        .Field {
            margin-top: 0;
        }

        input[type="email"] {
            padding-right: 90px;
        }
    }

    &-SubscribeButton {
        --button-background: transparent;
        --button-border-width: 0;
        --button-border-radius: 0;
        --button-color: var(--primary-dark-color);
        --button-font-weight: 500;
        --button-letter-spacing: 0;
        --button-font-size: 14px;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-dark-color);

        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        text-decoration: underline;
        text-transform: none;

        &:hover {
            // ...
        }
    }
}
