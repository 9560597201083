/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductLinks {
    padding-block: 30px;

    @include mobile {
        margin-block-start: 0;
    }

    @include desktop {
        padding-block: 60px;
    }

    &-Title {
        margin-block: 0 1.3em;
        padding: 0;
        font-weight: var(--h1-font-weight);
        font-size: var(--h1-font-size);
        text-align: center;

        @include mobile {
            font-size: var(--h1-font-size-mobile);
        }
    }

    &-List {
        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 20px;
        }
    }
}
