/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductInfoTri {
    &-Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        margin-block-end: 14px;
        opacity: .6;

        .Image {
            padding: 0;

            &-Image {
                position: relative;
                top: auto;
                width: auto;
                max-width: 100%;
                height: 100%;
                margin-inline: auto;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    }

    &-Content {
        p {
            font-size: 13px;
        }
    }
}
