/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductEcoPopup {
    .ContentWrapper {
        padding-inline: 0;
    }

    &-Item {
        & + & {
            margin-block-start: 40px;
        }

        &Title {
            margin-block: 0 1.3em;
            font-weight: 600;
            font-size: 18px;
            text-transform: none;
        }

        &Content {
            font-size: 13px;

            ul {
                li {
                    padding-inline-start: 1em;

                    &::before {
                        inset-inline-start: 0;
                    }
                }
            }
        }
    }
}
