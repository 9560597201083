/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductChips {
    position: absolute;
    top: 20px;
    right: 20px;
    width: auto;
    padding: 0.25em;
    aspect-ratio: 1;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: fluid(18px, 22px);
    line-height: fluid(18px, 22px);
    z-index: 5;
    box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.5);

    sup {
        color: #fff;
        font-size: fluid(10px, 14px);
        top: -5px;
    }

    @include desktop {
        top: 15%;
        right: 15%;
    }

    &-Discount {
        color: #fff;
        background-color: #C36A66;

        > * {
            color: #fff;
        }
    }

    &-New {
        background-color: #FCC9C6;
        color: #000;
    }
}

.ProductPage-WrapperGallery {
    .ProductChips {
        top: 45px;
        right: 7%;
        font-size: fluid(22px, 30px);
        line-height: fluid(22px, 30px);

        sup {
            top: -10px;
            font-size: fluid(16px, 30px);
        }

        @include desktop {
            top: 65px;
            right: 8%;
            font-size: fluid(22px, 36px);
            line-height: fluid(22px, 36px);

            sup {
                top: -20px;
                font-size: fluid(16px, 30px);
            }
        }
    }

    &:has(.ProductGallery_isSecondaryImage) {
        .ProductChips {
            display: none;
        }
    }
}

.ProductGallery_isMultiple + .ProductPage-TagsWrapper {
    .ProductChips {
        top: 25px;
        right: 12%;
        font-size: fluid(18px, 22px);
        line-height: fluid(18px, 22px);

        @include desktop {
            top: 30px;
            right: 10%;
            font-size: fluid(18px, 40px);
            line-height: fluid(18px, 40px);

            sup {
                top: -10px;
                font-size: fluid(12px, 24px);
            }
        }
    }
}

.SearchOverlay-TabList,
.ProductList-ProductCrossSell,
.CartPopup-CrossSellContent,
.CmsPage_type_tutorial .CmsPage-Content .RenderWhenVisible .ProductList,
.PushCollectionHomepage-Items .ProductList {
    .ProductChips {
        display: none !important;
    }
}
