/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPopup {
    .Popup-Content {
        height: 100%;
    }

    @include mobile {
        .ProductPage {
            margin-inline: 0;
        }
    }

    @include desktop {
        .ProductPage {
            &-Wrapper {
                display: block;
                padding: 0;
            }

            &-WrapperGallery {
                margin-inline: calc(var(--popup-content-padding) * -1);
            }

            .ProductGallery_isMultiple + .ProductPage-TagsWrapper {
                width: 100%;
                margin-inline-start: 0;
            }

            &-ProductActions {
                position: relative;
                top: auto;
            }
        }

        .ProductGallery-Slider {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }

            &ImageButton {
                cursor: default;
            }
        }

        .ProductTabs {
            margin-block-start: 0;

            .ProductInformation {
                padding-block-start: 25px;
                border-block-start: 1px solid var(--primary-divider-color);
            }
        }

        .ProductActions {
            &-Title {
                font-size: var(--h1-font-size-mobile);
            }

            &-ActionsWrapper {
                &_top {
                    display: flex;
                    margin-block: 0 20px;
                }
            }

            &-Reviews {
                .ProductReviewRating {
                    margin-block-end: 0;

                    &-Counter {
                        font-size: 12px;
                    }
                }

                .StarIcon {
                    width: 18px;
                    height: 18px;
                }
            }

            &-Section {
                &_type {
                    &_sku {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
