/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTabs {
    display: block;
    border-block-end-color: var(--primary-divider-color);

    &-Wrapper {
        .ExpandableContent-Heading {
            font-weight: 700;
            font-size: 14px;
        }
    }

    @include desktop {
        margin-block-start: 90px;
    }

    @include mobile {
        /* .ProductTab-Button {
            margin-left: -14px;
            margin-right: -14px;
            width: calc(100% + 28px);
            padding-left: 14px;
            padding-right: 14px;
        } */
        margin-left: -14px;
        margin-right: -14px;
        padding-left: 14px;
        padding-right: 14px;

        &-Wrapper {
            margin-left: -14px;
            margin-right: -14px;
        }

        .ProductExpandableContent {
            padding-left: 14px;
            padding-right: 14px;
        }

        .ProductTab-Button {
            padding-left: 14px;
            padding-right: 14px;
        }
    }
}
