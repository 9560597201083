/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewItem {
    border-width: 1px 0 0;

    &-SummaryDate {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &-ReviewDate {
        margin-block: 0;
        margin-left: 15px;
        font-weight: 300;
        font-size: 13px;
        color: #676767;
    }

    &-ReviewAuthor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: var(--paragraph-font-size);

        .user-group {
            display: inline-block;
            margin-top: 0.2em;
            padding: .2em .8em;
            border-radius: 10px;
            background-color: var(--color-black);
            font-weight: 400;
            font-size: 9px;
            color: var(--color-white);
            text-transform: uppercase;

            @include desktop {
                margin-top: 0.5em;
                font-size: 10px;
            }
        }

        strong {
            font-weight: 700;
        }
    }

    &-RatingSummaryItem {
        margin-block-end: 12px;

        &:not(:first-child) {
            border-block-start: 1px solid rgba(0, 0, 0, 0.5);
            padding-block-start: 12px;
        }
    }

    &-RatingItem {
        font-size: 12px;
        font-weight: 600;
    }

    &-Answer {
        grid-area: answer;
        grid-column-end: 3;
        margin-top: 15px;
        padding: 15px;
        background-color: var(--secondary-light-color);

        &Date {
            margin-block-end: 3px;
            font-weight: 300;
            font-size: 13px;
            color: #676767;
        }

        &Author {
            font-size: var(--paragraph-font-size);

            strong {
                font-weight: 700;
            }
        }

        &Details {
            p {
                margin-block-end: 0;

                & + p {
                    margin-block-start: 14px;
                }
            }

            .ExpandableText-Content {
                &::after {
                    @include scrimGradient($default-secondary-light-color, 'to top');
                }
            }
        }

        @include desktop {
            margin-top: 30px;
            padding: 30px;
        }
    }

    @include desktop {
        grid-template-areas: 'title .' 'content ratings' 'answer .';

        &-Content {
            &:last-child {
                grid-column-end: 3;
            }
        }
    }
}
