/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductComposition {
    /*&-Content {
        @include desktop {
            padding-block: 30px;
        }
    }

    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }*/

    &-Button {
        // ...
    }

    &-Options {
        & + .ProductComposition-LegalInformations {
            border-top-width: 0;
        }

        .ExpandableContent {
            &-Heading {
                text-transform: uppercase;
            }

            @include desktop {
                border-block-start: 1px solid var(--expandable-content-divider-color);

                &:last-of-type {
                    border-block-end: 1px solid var(--expandable-content-divider-color);
                }

                &-Button {
                    display: flex;
                    padding-block: 14px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-block-start: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &-LegalInformations {
        margin-top: 1.5em;
        padding-top: 1.5em;
        border-top: 1px solid var(--primary-divider-color);

        p {
            font-style: italic;
            // font-weight: 300;
            font-size: 13px;
        }
    }
}
