/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductVariation {
    display: flex;
    flex-wrap: wrap;

    &-Link {
        display: flex;
        align-items: center;
        column-gap: 15px;
        color: var(--default-color);
        cursor: pointer;

        &_isOutOfStock {
            opacity: .3;
        }
    }

    &_isLimited {
        .ProductVariation-Link {
            &_isOutOfStock {
                opacity: 1;
            }
        }

        .ProductVariation-Picture {
            margin-right: 0;
        }
    }

    &-Picture {
        flex: 0 0 auto;
        width: var(--variation-item-width, 22px);
        height: var(--variation-item-width, 22px);
        border-radius: 50%;
        overflow: hidden;
    }

    &-Name {
        display: flex;
        align-items: center;
        column-gap: 15px;
        font-weight: 400;
        text-align: start;

        &:first-child { // when no picture
            &::before {
                content: "";
                display: block;
                width: 20px;
                height: var(--variation-item-width, 22px);
            }
        }
    }

    &-Alert {
        &Button {
            margin-left: auto;
            font-weight: 500;
            color: var(--primary-dark-color);
            text-decoration: underline;
        }

        &Content {
            display: none;
            flex-basis: 100%;
            margin-top: 15px;

            &_isVisible {
                display: block;
            }
        }
    }

    &_isActive {
        .ProductVariation {
            &-Picture {
                &::after {
                    content: "";
                    display: block;
                    background: url($path-img + 'check-white.svg') no-repeat center;

                    @include full-cover();
                }
            }

            &-Name {
                &:first-child { // when no picture
                    &::before {
                        background: url($path-img + 'check-pink.svg') no-repeat center;
                        background-size: 15px auto;
                    }
                }
            }
        }
    }

    &_isSelect {
        min-height: var(--product-card-btn-addtocart-width);
        padding: 10px 14px;
        border: 1px solid var(--input-border-color);
        background-color: var(--input-background-color);
        border-radius: calc(var(--product-card-btn-addtocart-width) / 2);

        .ProductVariation {
            &-Link {
                width: 100%;
                column-gap: 10px;
            }

            &-Picture {
                --variation-item-width: 24px;

                flex: 0 0 auto;

                &::after {
                    display: none;
                }
            }

            &-Name {
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }

            .ChevronIcon {
                flex: 0 0 auto;
            }
        }
    }

    &_isInCart {
        .ProductVariation {
            &-Link {
                column-gap: 10px;
            }

            &-Name {
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}
