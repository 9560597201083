/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewsPopup {
    .ProductReviews-Button {
        &_isFormExpanded {
            --hollow-button-border: $color-black;
            --hollow-button-color: $color-black;
        }
    }

    &-Form {
        display: none;
        margin-top: 20px;
        margin-inline: calc(var(--popup-content-padding) * -1);
        padding: 20px var(--popup-content-padding);
        background-color: var(--primary-light-color);

        &Title {
            margin-top: 0;
            font-size: 16px;
            text-transform: none;
        }

        &_isFormExpanded {
            display: block;
        }
    }

    .ProductReviewList {
        margin-block-start: 28px;
    }

    .ProductReviewItem {
        display: block;

        &-RatingSummary {
            margin-block-start: 20px;
        }
    }
}
