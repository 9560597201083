/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-actions-btn-addtocart-height: 56px;
}

.ProductActions {
    &-Title {
        @include mobile {
            margin-block-start: 0;
            font-weight: var(--h1-font-weight-mobile);
            font-size: var(--h1-font-size-mobile);
        }
    }

    &-Section {
        &_type {
            &_sku {
                @include mobile {
                    flex-direction: row;
                    justify-content: flex-end;
                    font-weight: 500;
                }

                @include desktop {
                    font-size: 14px;
                }
            }

            &_alerts {
                margin-block: 0;
            }
        }
    }

    &-Reviews {
        margin-block-end: 0;

        &AnchorButton {
            &.Button {
                text-decoration: none;
            }
        }

        @include desktop {
            &AnchorButton {
                margin-block-end: 30px;
            }

            .ProductReviewRating {
                &-Counter {
                    font-size: 14px;
                }
            }

            .StarIcon {
                width: 25px;
                height: 25px;
            }
        }
    }

    &-Sku {
        @include mobile {
            font-weight: 500;
            margin-block-start: 0;
        }
    }

    &-Contenance {
        order: 2;
        display: flex;

        &:not(:first-child) {
            &::before {
                content: "-";
                margin-left: .4em;
                margin-right: .3em;
            }
        }

        @include mobile {
            font-size: 12px;
        }
    }

    &-ShortDescription {
        p {
            line-height: inherit;
        }

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;

        &Content {
            max-height: 99px;
            overflow: hidden;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 33px;
                position: absolute;
                inset-inline-start: 0;
                inset-block-start: 66px;

                @include scrimGradient(#fff, 'to top');
            }

            p, ul, ol, li {
                display: inline;

                &::after {
                    content: " \A\A";
                    white-space: pre;
                }
            }

            ul, ol {
                margin-inline-start: 0;

                li {
                    margin-inline-start: 20px;

                    &::after {
                        content: " \A";
                    }
                }
            }
        }

        &MoreButton {
            width: auto;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: .15em;
        }
    }

    .Button-PerfectCorp {
        display: block;
        width: 100%;
        margin: 0 auto 20px;

        > div {
            margin-left: 50px;

            &::before {
                content: "";
                display: inline-block;
                background: url($path-img + 'perfect/camera.svg') no-repeat center;
                background-size: contain;
                width: 50px;
                height: 50px;
                transform: translate(calc(-100% - 0.5em), -35%);
                position: absolute;
            }
        }

        &:hover {
            background-color: var(--button-background);
            border-color: var(--button-border);
        }
    }

    &-ActionsWrapper {
        margin-block: 20px 12px;

        @include desktop {
            margin-block: 24px;
        }

        &_top {
            margin-block: 0 20px;

            @include desktop {
                display: block;
                margin-block-start: 20px;
            }
        }
    }

    &-PriceWrapper {
        .ProductPrice {
            &-PriceValue {
                font-size: 20px;
            }
        }

        @include desktop {
            margin-top: 0;

            .ProductPrice {
                &-PriceValue {
                    font-size: 28px;
                }

                &::after {
                    font-size: 11px;
                }
            }
        }
    }

    &-ShippingDelay {
        font-size: 11px;
        font-weight: 500;
        opacity: .7;
    }

    &-Stock {
        &.unavailable {
            display: flex;
            align-items: center;

            &::before {
                flex: 0 0 auto;
                content: '';
                display: block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background-color: #FF0000;
                margin-right: .75em;
            }
        }
    }

    &-AddToCartWrapper {
        --button-height: var(--product-actions-btn-addtocart-height);
        --button-hover-height: var(--product-actions-btn-addtocart-height);

        margin-block: 0;

        .ProductActions-AddToCart {
            margin-inline-end: 0;

            @include ultra-narrow-desktop {
                margin-block-start: 0;
            }
        }

        .Loader {
            border-radius: 30px;
        }

        @include desktop {
            column-gap: 30px;
        }
    }

    &-AddToCartQuantity {
        flex: 0 0 100px;

        .Field {
            margin-block-start: 0;

            input {
                padding-inline: 30px 10px;
                height: var(--product-actions-btn-addtocart-height);
                border-radius: var(--button-border-radius);
                font-weight: 500;
                font-size: 16px;
                // text-align: center;
                // -moz-appearance: textfield;

                // &::-webkit-inner-spin-button,
                // &::-webkit-outer-spin-button {
                //     -webkit-appearance: none;
                // }
            }
        }

        & + .AddToCart {
            max-width: calc(100% - 130px);
        }

        @include mobile {
            display: none; // security
        }
    }

    &-Qty {
        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: var(--button-height);
            border: 2px solid var(--primary-base-color);
            border-radius: 50px;
        }

        &Label {
            margin-inline-end: 15px;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: .05em;

            @include desktop {
                font-size: 14px;
            }
        }

        &.Field {
            margin-inline-end: 0;
        }
    }

    &-ShippingInfo {
        display: flex;
        column-gap: 20px;
        margin-block: 20px;
        flex-wrap: wrap;


        @include desktop {
            margin-block-start: 45px;
        }

        @media (min-width: 1280px) {
            column-gap: 80px;
        }
    }

    &-ExtraReinsurance .WidgetContent {
        display: flex;
        column-gap: 20px;
        margin-block: 20px;
        flex-direction: row;
        gap: 20px;
        max-width: 100%;
        flex-wrap: wrap;


        @media (min-width: 1280px) {
            column-gap: 80px;
        }

        .reinsurance-item {
            &::before {
                content: "";
                display: block;
                width: 31px !important;
                height: 31px !important;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                flex: 0 0 31px;

                @include desktop {
                    width: 48px !important;
                    height: 48px !important;
                    flex: 0 0 48px;
                }
            }

            flex: 0 0 calc(50% - 10px);

            @include desktop {
                flex: 0 0 calc(50% - 40px);
            }

            align-items: center;
            display: flex;
            gap: 14px;
            margin-bottom: 20px;


            p {
                margin-block-end: 0;
                font-size: 11px;

                @include desktop {
                    font-size: 13px;
                }
            }
        }
    }

    &-ProductPickUpInfo {
        margin-block: 20px;
        padding: 0.3em 1em;
        background-color: #F5F5F5;
        font-weight: 500;
        font-size: 12px;
        color: #888;
        text-align: center;

        @include desktop {
            margin-block: 35px 0;
            padding-block: 0.8em;
            font-size: 13px;
        }
    }

    &-FixedTaxes {
        font-size: 11px;
        font-weight: 500;
    }

    &-FixedTax {
        &::before {
            content: "(";
        }

        &::after {
            content: ")";
        }
    }

    &-BundleInitialPrice {
        font-size: 11px;
        font-weight: 500;
    }

    &-ActionsFixed {
        position: fixed;
        z-index: 200;
        inset-inline-start: 0;
        inset-block-end: 0;
        width: 100%;
        padding-block: 10px;
        padding-inline: var(--content-wrapper-padding-mobile);
        background-color: var(--secondary-light-color);
        border-block-start: 1px solid var(--primary-divider-color);
        transform: translate(0, 100%);
        transition: transform 200ms cubic-bezier(.47, 0, .745, .715); // same as header

        &_isVisible {
            transform: translate(0, 0);
        }

        > * {
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }

        &Header {
            align-items: baseline;
            margin-block-end: 10px;
        }

        &Content {
            align-items: center;
        }

        .ProductActions-Name {
            flex: 1 1 auto;
            margin-block-end: 0;
            font-weight: 700;
            font-size: 11px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .ProductActions-PriceWrapper {
            flex: 0 0 auto;

            .ProductPrice {
                &-PriceValue {
                    font-size: 14px;
                }

                &-UserGroupLabel,
                &-Price_isPublic {
                    display: none;
                }
            }
        }

        .ProductVariations {
            max-width: calc(100% - 106px);
            margin-block-end: 0;
        }

        .ProductActions-AddToCartWrapper {
            flex: 0 0 auto;
            margin-inline-start: auto;
        }

        .ProductActions-QtyWrapper {
            min-height: var(--product-card-btn-addtocart-width);
            border: none;
            border-radius: 0;
        }

        .ProductActions-QtyLabel {
            display: none;
        }

        .AddToCart {
            @include addToCartIconOnly();
        }
    }

    &-RestrictedTags {
        display: flex;
        gap: 10px;
        margin-block-end: 10px;

        .ProductTag {
            margin: 0;
            padding: 0.5em 0.75em;
            font-size: #{fluid(13px, 15px)};
            font-weight: 400;
            text-transform: none;
        }
    }
}
