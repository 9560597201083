/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductInformation {
    &-Content {
        border-block-start: 1px solid var(--expandable-content-divider-color);
    }

    &-ExpandableContentContent {
        line-height: inherit;
        text-align: start;

        &_isContentExpanded {
            margin-block-end: 30px;
        }
    }

    @include desktop {
        &-Content {
            margin-block-end: 32px;
            border-block-start-width: 0;
        }

        &-Wrapper {
            padding-block: 0;
        }

        &-ExpandableContentButton {
            margin-block-end: 30px;
        }

        &-ExpandableContentHeading {
            display: flex;
        }
    }

    &-ShortDescription {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-block-end: 17px;
        line-height: inherit;

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;
    }

    &-Description {
        @include description-tags {
            font-size: var(--paragraph-font-size);
            line-height: var(--paragraph-line-height);
        }

        p {
            &:last-child {
                margin-block-end: 0;
            }
        }

        li {
            &::before {
                margin-inline-end: 0;
            }
        }

        @include mobile {
            line-height: inherit;

            @include description-tags {
                font-size: var(--paragraph-font-size-mobile);
                line-height: var(--paragraph-line-height-mobile);
            }
        }
    }

    &-Attributes {
        //display: grid;
        //grid-template-columns: auto 1fr;
        //align-items: center;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-block-start: 20px;

        &:first-child {
            margin-block-start: 0;
        }
    }

    &-Attribute {
        //display: contents;
        display: flex;
        align-items: center;

        &[data-attribute="pao"] {
            .ProductInformation-AttributeLabel {
                &::before {
                    flex: 0 0 auto;
                    content: "";
                    display: block;
                    width: 41px;
                    height: 31px;
                    margin-inline-end: 15px;
                    background: url($path-img + 'pao.svg') no-repeat center;
                    background-size: contain;
                }
            }
        }
    }

    &-AttributeLabel {
        display: flex;
        align-items: center;
        min-block-size: 30px;
        font-weight: 700;

        &::after {
            content: ":";
            margin-inline: .3em .5em;
        }
    }

    &-AttributeValue {
        // ...
    }

    &-CategoryText {
        &Item {
            margin-block-start: 35px;
        }

        &Title {
            margin-block-end: 15px;
            font-weight: 700;
            font-size: 14px;
        }

        &Content {
            font-weight: 300;
            font-size: 14px;

            ul {
                li {
                    display: flex;
                    align-items: flex-start;
                    column-gap: 10px;
                    margin-block-end: 0;

                    & + li {
                        margin-block-start: 10px;
                    }

                    &::before {
                        flex: 0 0 auto;
                        content: "";
                        display: block;
                        position: static;
                        width: 18px;
                        aspect-ratio: 1;
                        padding: 0;
                        background: url($path-img + 'check-rounded.svg') no-repeat center;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
