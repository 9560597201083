/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewsYotpo {
    .yotpo {
        .yotpo-bottomline {
            .yotpo-icon-star,
            .yotpo-icon-empty-star {
                color: var(--primary-base-color);
            }
        }

        .yotpo-review,
        .yotpo-comment,
        .yotpo-question,
        .yotpo-onsite-upload {
            .yotpo-header .yotpo-review-stars .yotpo-icon {
                color: var(--primary-base-color);
            }
        }

        .yotpo-first-review .yotpo-first-review-stars .yotpo-icon-star {
            color: var(--primary-base-color);
        }

        .write-review .yotpo-header .yotpo-icon {
            color: var(--primary-base-color);
        }
    }

    .write-question-review-buttons-container {
        .write-question-review-button {
            .write-question-review-button-icon {
                color: var(--primary-base-color) !important;
            }
        }
    }

    .yotpo .primary-color-btn, .yotpo input[type="button"].primary-color-btn {
        background-color: var(--primary-base-color);

        &:hover {
            background: var(--button-hover-background) !important;
        }
    }

    &_isDesktop {
        .ProductReviewsYotpo-Title {
            display: block;
        }

        @include desktop {
            padding-block: 60px;

            .yotpo-display-wrapper.main-widget {
                padding-inline-start: 28%;

                .yotpo-display-wrapper,
                .write-question-review-buttons-container {
                    position: absolute;
                    inset-inline-start: 0;
                    width: calc(28% - 30px);
                    text-align: center;
                }

                .yotpo-display-wrapper {
                    inset-block-start: 0;
                }

                .write-question-review-buttons-container {
                    inset-block-start: 100px;
                    padding-inline: 0 !important;
                }
            }
        }

        @media screen and (min-width: 961px) {
            .bottom-line-items {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .avg-score {
                margin-block-end: 10px;
            }

            .yotpo-display-wrapper.main-widget {
                .write-question-review-buttons-container {
                    inset-block-start: 150px;
                }
            }
        }
    }

    &-Title {
        display: none;
        margin-block: 0 1em;
        font-weight: var(--h1-font-weight);
        font-size: var(--h1-font-size);
        text-align: center;
    }

    li {
        padding-inline: 0;

        &::before {
            content: "";
            display: none;
        }
    }
}

.yotpo-review-form-modal {
    &.modal-backdrop {
        z-index: 1000 !important;
    }
}

.ProductPopup {
    .ProductReviewsYotpo {
        .yotpo-layout-header-wrapper {
            flex-direction: column !important;
        }

        .yotpo-star-distribution {
            padding: 20px 0;
        }

        .yotpo-base-layout {
            width: 100% !important;
        }

        .yotpo-filters-top-panel {
            flex-direction: column !important;
            gap: 16px;
            width: 100%;

            input {
                width: 100%;
            }
        }

        .yotpo-search-filter,
        .yotpo-media-filter,
        .yotpo-clear-filters,
        .yotpo-clear-filters-btn {
            display: none !important;
        }

        .yotpo-empty-state {
            .yotpo-body-text {
                display: none !important;
            }
        }

        .yotpo-free-search-filter-container {
            max-width: 100% !important;
            flex-basis: 100% !important;
        }

        .yotpo-review-card {
            position: relative !important;
            left: 0 !important;
            top: 0 !important;
            width: 100% !important;
        }

        .yotpo-bottom-line {
            display: block;
        }

        .yotpo-filters-container,
        .yotpo-reviews-container {
            @include mobile {
                padding: 0 20px;
            }
        }

        .yotpo-review-left-panel {
            order: 0 !important;
            flex-basis: 50%  !important;
        }

        .yotpo-review-center-panel {
            display: flex;
            flex-direction: column;
            flex-basis: 100% !important;
            order: 1 !important;
            margin-top: 10px;
            max-width: 100% !important;
        }

        .yotpo-review-rating-title {
            display: block !important;
        }

        .yotpo-review-star-rating {
            margin-bottom: 15px !important;
        }

        .yotpo-review-title {
            flex-basis: 100% !important;
            order: 2 !important;
            margin-left: 0 !important;
            color: var(--text-color);
            font-weight: var(--primary-font-weight);
            font-family: var(--primary-font-family);
            font-style: var(--primary-font-style);
        }

        .yotpo-review-right-panel {
            order: 0 !important;
            flex-basis: 50% !important;
            text-align: end !important;
            margin-top: 5px !important;
        }

        .yotpo-new-review-btn-wrapper {
            display: none !important;
        }

        .yotpo-thumbnail-container {
            display: none !important;
        }
    }
}

#yotpo-mobile-filters-popup {
    z-index: 100001;

    .yotpo-popup-close-button-wrapper {
        display: block;

        .yotpo-mobile-filters-popup-close {
            float: right;
            padding-right: 2em;
            z-index: 100002;
        }
    }

    .yotpo-filter-select,
    .yotpo-media-select {
        top: 0px !important ;
    }
}
