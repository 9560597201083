/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-card-background: transparent;
    --product-card-btn-addtocart-width: 46px;
    --product-card-image-padding: 0%;

    @include desktop {
        --product-card-btn-addtocart-width: 62px;
        --product-card-image-padding: 10%;
    }
}

.ProductCard {
    height: 100%;
    margin-block-end: 0;
    container: productCard / inline-size;

    &::before {
        display: none;
    }

    &:last-child {
        margin-block-end: 0;

        @include mobile {
            margin-block-end: 0;
        }
    }

    &:hover {
        @include desktop {
            box-shadow: none;
        }
    }

    &-ProductActions {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding: 15px;
        margin-inline-start: 0;

        @include mobile {
            padding: 11px;
        }

        .ProductWishlistButton {
            flex: 0 0 auto;
            margin-inline-end: 0;

            @include mobile {
                height: 15px;
                width: 15px;
            }

            &:first-child {
                margin-left: auto;
            }

            &-Button.Button {
                @include mobile {
                    height: 15px;
                    width: 15px;

                    svg {
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
    }

    &-OutOfStock {
        height: auto;

        p {
            font-size: 11px;

            @include desktop {
                font-weight: 700;
                padding-right: 4em;
            }
        }
    }

    &-FigureReview {
        @include desktop {
            padding: 0;
        }
    }

    &-Figure {
        &.swiper {
            margin-inline: 0;
        }
    }

    &-Pictures {
        --swiper-pagination-bullet-horizontal-gap: 2px;
        --swiper-pagination-color: var(--secondary-base-color);
        --swiper-pagination-bullet-inactive-color: transparent;
        --swiper-pagination-bullet-inactive-opacity: 1;

        .swiper-pagination {
            &-bullet {
                border: 1px solid var(--swiper-pagination-color);
            }
        }

        @include mobile {
            .swiper-slide {
                &:not(:first-child) {
                    .ProductCard-Picture {
                        .Image-Image {
                            width: 100%;
                            height: 100%;
                            inset-block-start: 0;
                            inset-inline-start: 0;
                        }
                    }
                }
            }
        }

        @include desktop {
            > * {
                &:not(:first-child) {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: 2;
                    transition: opacity .2s ease-out;
                }
            }

            &:hover {
                > * {
                    &:not(:first-child) {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-Picture {
        display: block;
        height: 0;
        padding-block-end: var(--ratio-product-image);

        .Image-Image {
            width: 100%;
        }

        &:first-child {
            .Image-Image {
                width: calc(100% - var(--product-card-image-padding) * 2);
                height: calc(100% - var(--product-card-image-padding) * 2);
                inset-block-start: var(--product-card-image-padding);
                inset-inline-start: var(--product-card-image-padding);
            }
        }
    }

    &-Content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding-block: 15px 0;

        @include desktop {
            padding-inline: 0;
        }

        @include mobile {
            padding-top: 11px;
        }
    }

    &-Contenance {
        font-weight: normal;
        font-size: 0.9em;
    }

    &-Name {
        margin-top: 0;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: normal;
        line-height: 1.3;
        margin-bottom: 0;
        padding-bottom: 0;

        @include desktop {
            font-size: 15px;
            max-width: calc(100% - 65px);
        }

        @include mobile {
            max-width: calc(100% - 45px);
        }

        @container productCard (max-width: 185px) {
            max-width: none;
        }
    }

    &-Reviews {
        height: auto;
        margin-block: 3px;

        .StarIcon {
            width: 12px;
            height: 12px;
        }

        @include desktop {
            margin-block: 8px;

            .StarIcon {
                width: 17px;
                height: 17px;
            }
        }
    }

    &-Footer {
        margin-block-start: auto;
        align-items: stretch;

        > * {
            margin-inline-end: 0;
        }

        @include mobile {
            padding-block-start: 0;
        }

        @include desktop {
            padding-block-start: 0;
        }

        &Inner {
            display: flex;
            flex-direction: column;

            &:last-child {
                width: 100%;
                margin-inline-end: 0;
            }

            > *:last-child {
                margin-block-start: auto;
            }
        }

        &PriceWrapper {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            column-gap: 5px;
            row-gap: 8px;

            @container productCard (max-width: 185px) {
                flex-wrap: wrap;
            }
        }
    }

    & &-Footer {
        &_isProEtu {
            @include mobile {
                .AddToCart,
                &-QtyWrapper {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-Price {
        font-size: 13px;

        @include desktop {
            font-size: 15px;
        }
    }

    .AddToCart {
        @include addToCartIconOnly();

        margin-block-start: -20px;

        @container productCard (max-width: 185px) {
            margin-block-start: unset;
        }
    }

    &-VariationsLink {
        margin-inline-start: auto;
        text-decoration: underline;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Qty {
        margin-block-start: 0;

        &Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            min-height: var(--product-card-btn-addtocart-width);
            margin-block-start: -20px;

            @include mobile {
                margin-block-start: -10px;
            }

            @container productCard (max-width: 185px) {
                flex: 1 1 auto;
                min-height: 0;
                margin-block-start: 0;
            }
        }

        &Label {
            display: none;
        }
    }

    &-SkuAndContenance {
        display: flex;
        font-size: 12px;
        font-weight: 300;

        > * + * {
            &::before {
                content: '-';
                margin-inline: .3em;
            }
        }
    }
}
