/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductDocuments {
    &-Categories {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin-block-end: 40px;
    }

    &-Category {
        &Name {
            margin-block: 0 0.5em;
            font-size: 22px;
            font-weight: 300;
        }
    }

    &-Items {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .ProductRestricted {
        margin-inline: calc(var(--popup-content-padding) * -1);
        padding-block: 40px;
    }

    .Popup & {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .ProductRestricted {
            margin-block-start: auto;
        }
    }
}
