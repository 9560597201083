/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductList {
    @include desktop {
        padding-block-start: 1px; // prevent margin bug with clear both
    }

    &-LoadButtonWrapper {
        margin-block: 25px 10px;
        text-align: center;

        @include desktop {
            margin-block-end: 15px;
        }
    }

    &-LoadButton {
        --hollow-button-height: 40px;
        --hollow-button-border: var(--secondary-base-color);
        --hollow-button-color: var(--secondary-base-color);
        --button-font-size: 11px;
        --hollow-button-hover-height: var(--hollow-button-height);

        width: 100%;
        max-width: 340px;
        background-color: var(--hollow-button-background);
        font-size: var(--button-font-size);
        margin-block-end: 0;
        padding-block: 0;
        padding-inline: var(--hollow-button-padding);

        @include mobile {
            margin-block-end: 0;
        }
    }

    &-ProductCrossSell {
        .ProductCard-Content {
            .ProductCard-Name {
                font-size: 12px;
            }
        }
    }

    &Widget {
        &.swiper {
            .swiper-scrollbar {
                & ~ .swiper-scrollbar { // fix bug duplication of scrollbar
                    pointer-events: none;
                    opacity: 0;
                }
            }

            .swiper-scrollbar-drag {
                background-color: var(--primary-dark-color);
            }
        }
    }
}
