/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartGiftProductsChoice {
    margin-block: 20px;

    @include desktop {
        margin-block-end: 40px;
    }

    /*&-Wrapper {
        display: grid;
        grid-template-columns: var(--cart-item-grid-template, 1fr 1fr 1fr 1fr);
        grid-template-areas: 'info info info info' 'qty qty price actions';
        grid-gap: var(--cart-item-grid-gap);
        padding: 10px 0;
        align-items: center;

        @media (min-width: 1024px) {
            grid-template-areas: 'info info qty price actions';
        }
    }*/

    &-Info {
        grid-area: info;
        display: flex;
    }

    &-Visual {
        width: var(--cart-item-product-visual-width);
        aspect-ratio: 1.5;
        align-self: flex-start;

        span {
            display: block;
            height: 100%;
            background-color: var(--secondary-light-color);
            background-image: url($path-img + 'gift.svg');
            background-size: 36px 36px;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        //width: 65%;
        overflow: hidden;
        padding-left: 20px;

        h3 {
            margin-block: 0 .3em;
            font-size: #{fluid(14px, 16px)};
            font-weight: 700;
            text-transform: none;
        }
    }

    &-Description {
        margin-block-end: .3em;
    }

    &-SelectedProducts {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-block-start: 10px;
        margin-block-end: 8px;
        font-size: 13px;
    }

    &-SelectedProduct {
        display: grid;
        //grid-template-columns: 50px minmax(100px, 3fr) 1fr 1fr 80px;
        grid-template-columns: 50px auto;
        align-items: center;
        gap: var(--cart-item-grid-gap);

        &Visual {
            &Thumbnail {
                display: block;
                width: 50px;
                height: auto;
                aspect-ratio: var(--aspect-ratio-product-image);
                margin-inline: auto;
            }
        }

        &Name {
            &:not(:only-child) {
                margin-block-end: 5px;
            }
        }
    }

    &-Button {
        width: auto;
        color: var(--primary-dark-color);
        font-weight: 500;
        text-decoration: underline;
    }

    &-Quantity {
        grid-area: qty;
        text-align: center;
        display: none;
    }

    &-Price {
        grid-area: price;
        font-weight: var(--paragraph-font-weight);
        font-size: 16px;
        text-align: center;
    }

    &-Products {
        margin-block-start: 15px;

        &.swiper {
            padding-block-end: 35px;

            .swiper-scrollbar {
                & ~ .swiper-scrollbar { // fix bug duplication of scrollbar
                    pointer-events: none;
                    opacity: 0;
                }
            }

            .swiper-scrollbar-drag {
                background-color: var(--primary-dark-color);
            }

            &:has(.swiper-scrollbar-lock) {
                padding-block-end: 0;
            }
        }
    }

    &_hasNoGiftChecked {
        .CartGiftProductsChoice-Products {
            &::after {
                @include full-cover(true);

                background-color: rgba(255,255,255,.5);
                z-index: 5;
            }
        }
    }

    @include mobile() {
        &-Wrapper {
            grid-template-columns: 64px 1fr 1fr;
            grid-template-areas: 'info info info' 'price price price';
            grid-gap: 14px;
            row-gap: 0;
            padding-block: 14px;
        }

        &-Visual {
            flex: 0 0 auto;
            width: 105px;
            height: 70px;
            margin-right: 14px;
        }

        &-Content {
            padding-inline-start: 0;
            width: auto;
        }

        &-PriceMobile {
            margin-block-end: 8px;
        }

        &-Price {
            display: none;
        }
    }

    .CartGiftProductsChoice-Wrapper_isMultiple {
        .CartGiftProductsChoice-Visual span {
            background-image: url($path-img + 'products.svg');
            background-size: 48px 34px;
        }
    }

    .swiper-button-prev {
        left: 0;
        margin-left: 0;
        max-height: 38px;
        max-width: 38px;
    }

    .swiper-button-next {
        right: 0;
        margin-right: 0;
        max-height: 38px;
        max-width: 38px;
    }
}
